@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



@font-face {
  font-family: 'Philosopher';
  src: url('./fonts/Philosopher-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Philosopher';
  src: url('./fonts/Philosopher-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Philosopher';
  src: url('./fonts/Philosopher-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'CormorantGaramon';
  src: url('./fonts/CormorantGaramond-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CormorantGaramon';
  src: url('./fonts/CormorantGaramond-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'CormorantGaramon';
  src: url('./fonts/CormorantGaramond-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


.titre {
  font-family: 'Philosopher'
}


.max-h-80vh{
  max-height: 80vh;
}

.h-10vh{
  height: 10vh;
}

.mb-3em{
  margin-bottom: 3em;
}

.left_to_right {
  animation: slideLeftToRight 0.25s ease-in-out forwards;
}

.right_to_left {
  animation: slideRightToLeft 0.25s ease-in-out forwards;
}

.right_to_leftv2 {
  animation: slideRightToLeftV2 0.5s ease-in-out forwards;
}

.top_to_bot {
  animation: slideTopToBot 0.25s ease-in-out forwards;
}

.bot_to_top {
  animation: slideBotToTop 0.25s ease-in-out forwards;
}

@keyframes slideLeftToRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideRightToLeftV2 {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideRightToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slideTopToBot {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideBotToTop {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}



/** Gallery **/
.gallery-slider {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.slider-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.slide.active {
  opacity: 1;
}

.prev-btn,
.next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  z-index: 2;
}

.prev-btn {
  left: 20px;
}

.next-btn {
  right: 20px;
}

.preview-container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 2;
}

.preview {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin: 0 10px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.preview.active {
  opacity: 1;
}


